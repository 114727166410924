import React from "react"

export default function LeadForm() {
  return (
    <form
      class="bg-gray-200 px-4 py-8 rounded-xl shadow-lg lg:mr-12"
      name="leadForm"
      method="POST"
      action="/thank-you"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
    >
      <input class="hidden" name="bot-field" />
      <div class="mb-4">
        <input
          class="text-brand-gray bg-gray-300 py-3 px-6 border-0 rounded-full w-full focus:bg-white focus:outline-none focus:border-0 focus:ring-0 focus:shadow-inner"
          type="text"
          name="name"
          placeholder="Name"
        />
      </div>
      <div class="mb-4">
        <input
          class="text-brand-gray bg-gray-300 py-3 px-6 border-0 rounded-full w-full focus:bg-white focus:outline-none focus:border-0 focus:ring-0 focus:shadow-inner"
          type="email"
          name="email"
          placeholder="Email"
        />
      </div>
      <div class="mb-4">
        <input
          class="text-brand-gray bg-gray-300 py-3 px-6 border-0 rounded-full w-full focus:bg-white focus:outline-none focus:border-0 focus:ring-0 focus:shadow-inner"
          type="tel"
          name="tel"
          placeholder="Phone"
        />
      </div>
      <div class="mb-4">
        <textarea
          class="text-brand-gray bg-gray-300 py-3 px-6 border-0 rounded-xl w-full focus:bg-white focus:outline-none focus:border-0 focus:ring-0 focus:shadow-inner"
          name="message"
          placeholder="Message"
          cols="30"
          rows="10"
        ></textarea>
      </div>
      <input type="hidden" name="form-name" value="leadForm" />
      <button type="submit" class="btn">
        Send
      </button>
    </form>
  )
}
