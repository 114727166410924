import React from "react"
import Footer from "./Footer"
import Header from "./Header"

export default function Layout({ children }) {
  return (
    <div className="site-wrapper font-brand text-brand-darkgray">
      <Header></Header>
      {children}
      <Footer></Footer>
    </div>
  )
}
