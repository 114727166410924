import React from "react"
import Layout from "../components/Layout"
import LeadForm from "../components/LeadForm"
import { StaticImage } from "gatsby-plugin-image"
import Seo from "../components/seo"

export default function ContactUs() {
  return (
    <Layout>
      <Seo
        title="Contact Us"
        description="CAST is a South African turnkey rope access service provider. Contact us today!"
        pathname="/contact-us"
      />
      <section className="hero hero--contact-us px-4 bg-brand-blue flex items-center relative">
        <StaticImage
          className="absolute top-0 right-0 bottom-0 left-0 z-0 h-full w-full object-cover"
          src="../images/hero-contact-us-v2.jpg"
          alt="Contact Us"
          placeholder="blurred"
        />
        <div className="container mx-auto relative z-10">
          <div className="hero__content text-center">
            <h1 className="text-white pb-4 relative">
              Contact Us
              <span className="border-b-2 border-brand-orange inline-block h-1 w-24 absolute bottom-0 left-1/2 transform -translate-x-1/2"></span>
            </h1>
          </div>
        </div>
      </section>
      <section className="px-4 py-24">
        <div className="container mx-auto lg:flex">
          <div className="form-col lg:w-1/2 mb-12 mx-auto">
            <LeadForm />
          </div>
          {/* <div className="lg:w-2/3 sm:grid sm:grid-cols-2 md:grid-cols-3 sm:gap-6"></div> */}
        </div>
      </section>
    </Layout>
  )
}
